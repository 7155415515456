
.map-section {
	max-width: 1555px;
	margin: 15px auto;
	width: 100%;
	margin-bottom: 60px;
	box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
	background: radial-gradient(381.87% 142.13% at 0% 0%, rgb(117, 87, 242) 23.979711532592773%, rgb(219, 212, 248) 100%);
	border-radius: 8px;
	padding: 44px 72px;
	color: #fff;
	position: relative;
	min-height: 396px;
	overflow: hidden;

	&::before {
	    position: absolute;
	    bottom: -2px;
		right : 118px;
		width: 536px;
		height: 396px;
		background: url("../img/map.png");
		content: '';
		z-index: 0;
	}

	.title {
		font-weight: 700;
		font-size: 36px;
		margin-bottom: 20px;
	}

	&__item {
		font-weight: 400;
		font-size: 18px;
		line-height: 145%;
		margin-bottom: 12px;
	}

	.btn {
		margin-top: 41px;
		font-weight: 600;
		font-size: 24px;
		color: #24395f;
		border-radius: 12px;
		width: 446px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: 1s;
		border: none;
		text-align: center;
		line-height: 1;
		background: #fff;
		text-decoration: none;
		&:hover {
			opacity: .6;
		}
	}

	@media (max-width: 1445px) {
		&::before {
			bottom: -100px;
			right : 18px;
			width: 536px;
			height: 396px;
		}
	}
	@media (max-width: 1180px) {
		&::before {
			bottom: -100px;
			right : -200px;
			width: 536px;
			height: 396px;
		}

		.title {
			font-size: 20px;
		}

		&__item {
			font-weight: 400;
			font-size: 12px;
		}

		.btn {
			margin-top: 88px;
			font-size: 16px;
			border-radius: 8px;
			width: 310px;
			height: 48px;

		}
	}

}

